.Heading {
    padding-bottom: 2rem;
}

@media (max-width: 768px) {
    .display-4 {
        font-size: 200%;
      }
}

@media (max-width: 576px) {
    .display-4 {
        font-size: 160%;
    }
}
