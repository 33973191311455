.padding-btm {
    padding-bottom: 2rem;
}
.fa-gem{
    color: #aba8ad;
  }
  
  .fa-paint-brush{
    color: #643d45;
  }
  
  .fa-birthday-cake{
    color: #661929;
  }
  
  .fa-gem, .fa-paint-brush, .fa-birthday-cake {
    font-size: 4rem;
    margin: 1rem;
  }