.Contact {
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-form {
  width: 100%;
  max-width: 900px;
}