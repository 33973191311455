.carousel-small {
  height: 300px;
  background-position: center center;
  background-size: cover;
}

#slides {
  display: none;
}

.carousel-item img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.carousel-caption {
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
}

.carousel-caption h1 {
  font-size: 250%;
  font-style: italic;
  font-family: georgia;
  text-shadow: 1px 1px 1px #000;
  color: white;
}

.carousel-caption h3 {
  font-size: 110%;
  font-weight: 500%;
  text-shadow: 1px 1px 1px #000;
  padding-bottom: 1rem;
  color: white;
}

.carousel-caption .btn {
  font-size: 90%;
  padding: 4px 8px;
}

.carousel .carousel-indicators li {
  background-color: #661929;
}

.carousel .carousel-indicators .active {
  background-color: #4e131f;
}

.btn-primary {
  background-color: #661929;
  border: 1px solid #661929;
}

.btn-primary:hover {
  background-color: #4e131f;
  border: 1px solid #661929;
}


@media (min-width: 576px) {
  .carousel-small {
    display: none;
  }

  .carousel-caption {
    top: 45%;
  }

  .carousel-caption h1 {
    font-size: 350%;
  }

  .carousel-caption h3 {
    font-size: 140%;
    font-weight: 400%;
    padding-bottom: .2rem;
  }

  .carousel-caption .btn {
    font-size: 95%;
    padding: 8px 14px;
  }

  #slides {
    display: block;
  }


}

@media(min-width: 768) {
  .carousel-caption {
    top: 40%;
  }

  .carousel-caption h1 {
    font-size: 500%;
  }

  .carousel-caption h3 {
    font-size: 300%;
  }

}

/*---Firefox Bug Fix --*/
.carousel-item {
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
}