.Faq {
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.faq-container{
  width: 100%;
  max-width: 900px;
}