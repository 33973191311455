.icon-radio {
  opacity: 0;
  position: absolute;
  width: 0;
  height: 0;
}

.icon-radio:focus {
  outline: black;
}

.icon-label-selected {
  border: 2px #661929 solid;
  background-color: #fff7f9;
  color: #661929;
  font-weight: bold;
}

.icon-label {
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  border-radius: 0.25rem;
  font-size: 1.125rem;
}

.icon-label:first {
  margin-right: 8px;
}

.icon-label:not(:first) {
  margin-left: 8px;
}

.icon-label:not(.icon-label-selected) {
  border: 2px #ced4da solid;
  filter: grayscale(100%);
}

.icon-label > svg,
.icon-label > img {
  height: 112px;
  width: 112px;
  margin: 8px;
}

.icon-radio-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(271px, 1fr));
  width: 100%;
  column-gap: 8px;
}

.form-image-upload input {
    border: none;
}

.form-image-upload {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.form-image-upload img {
    height: 200px;
    width: 200px;
    object-fit: contain;
}

.img-col {
    display: flex;
    flex-direction: column;
}

.img-col > button {
    margin-top: 24px;
    font-size: 1rem;
}

@media (max-width: 378px) {
  .icon-radio-wrapper {
    grid-template-columns: 1fr;
  }
}
