.Order {
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.Order > small {
  float: left;
  width: 100%;
  max-width: 900px;
}

.button-container {
  padding: 0;
}

.order-form {
  width: 100%;
  max-width: 900px;
}

.star {
  color: red;
}
