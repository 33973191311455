.nav-active {
  color: #333333;
  font-weight: bold;
}

.App {
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.content-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
 width: 100%;
 max-width: 1200px;
}

.navbar {
 width: 100%;
}

.navbar-brand img {
  margin-right: 8px;
  margin-bottom: 8px;
}

.form-group {
  margin: 1.125rem 0px;
}

.form-control {
  font-size: 1.125rem;
}

.alert {
  bottom: 0px;
}

.btn {
  padding: 12px 36px;
  font-size: 1.125rem;
}

.hidden {
  display: none;
}

@media only screen and (min-width: 1900px) {
  .navbar-content {
    width: 1900px;
  }
}

