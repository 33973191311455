.Footer {
    bottom: 0;
    background-color: #f8f9fa;
    padding-top: 2rem;
    width: 100%;
    margin-top: 16px;
}

hr.light{
    border-top: 1px solid #d5d5d5;
    width: 75%;
    margin-top: .8rem;
    margin-bottom: 1rem;
}
  
hr.light-100 {
    border-top: 1px solid #d5d5d5;
    width: 100%;
    margin-top: .8rem;
    margin-bottom: 1rem;
}

.social a{
    font-size: 4.5rem;
    padding: 3rem;
}

.fa-facebook{
    color: #3b5998;
}

.fa-instagram {
    color: #8a3ab9;
}

.fa-facebook:hover, .fa-instagram:hover{
    color: #d5d5d5;
}

.row {
    margin-left: 0;
    margin-right: 0;
}