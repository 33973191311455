.qoute {
    font-size: 2rem;
    color: #661929;
    text-align: right;
}

.qoute small {
    color: black;
    font-size: 1rem;
}

.qoute p {
    margin-top: -1rem;
    font-weight: 500;
}